import { Modal } from "antd";
import { useModal } from "../../redux/modalReducer";

export default function ModalBase({ title, content }) {
  const modal = useModal();
  const handleCloseModal = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    modal.close();
  };

  return (
    <Modal
      getContainer="#root"
      title={title || null}
      visible={true}
      centered={true}
      footer={null}
      onCancel={handleCloseModal}
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ paddingBottom: "0px" }}
    >
      {content}
    </Modal>
  );
}
