import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CONFIRM_URL, useRequest } from "../../url/URL";

export default function ConfirmAccountView() {
  const { id, token } = useParams();
  const navigate = useNavigate();

  const handleConfirmAccount = useRequest({
    method: "PUT",
    url: CONFIRM_URL + id + "/" + token,
  });

  useEffect(() => {
    handleConfirmAccount();
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
