import { Button } from "antd";
import { useModal } from "../../redux/modalReducer";

export default function ConfirmModalContent({
  text,
  onOk,
  onCancel,
  theme = "primary",
}) {
  const modal = useModal();
  return (
    <>
      {text && <p className="my-10">{text}</p>}
      <div className="center-row pt-10">
        <Button
          className="secondary-button w-50"
          onClick={onCancel ? onCancel : modal.close}
        >
          Nie
        </Button>

        <Button
          className={`${theme}-button w-50`}
          onClick={onOk ? onOk : modal.close}
        >
          Tak
        </Button>
      </div>
    </>
  );
}
