import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { useWindowWidth } from "../redux/windowReducer";

export default function NavBar({ options }) {
  const [disableHamburger, setDisableHamburger] = useState(false);
  const [animateMenu, setAnimateMenu] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const windowWidth = useWindowWidth();
  const ref = useRef(null);

  const handleHamburger = useCallback(() => {
    const optLen = options.length;
    if (!disableHamburger) {
      setDisableHamburger(true);
      setTimeout(() => setDisableHamburger(false), optLen * 50);
      const className =
        animateMenu === `menu-show-${optLen}`
          ? `menu-hide-${optLen}`
          : `menu-show-${optLen}`;
      setAnimateMenu(className);
      if (showMenu) setTimeout(() => setShowMenu(!showMenu), optLen * 50 - 5);
      else setShowMenu(!showMenu);
    }
  }, [animateMenu, disableHamburger, options.length, showMenu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && showMenu) {
        handleHamburger();
      }
    }
    if (windowWidth < 768)
      document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [windowWidth, showMenu, ref, handleHamburger]);

  return (
    <div ref={ref} className="navbar">
      {windowWidth < 768 ? (
        <>
          <Button
            icon={<MenuOutlined />}
            className="navbar-icon"
            onClick={handleHamburger}
          ></Button>
          <nav
            className={animateMenu}
            style={{ display: showMenu ? "block" : "none" }}
          >
            <ul>
              {options.map((option, index) =>
                option.link ? (
                  <Link to={option.link} key={index}>
                    <li
                      className={`navbar-item${option.active ? "-active" : ""}`}
                    >
                      {option.text}
                    </li>
                  </Link>
                ) : (
                  <li
                    className={`navbar-item${option.active ? "-active" : ""}`}
                    onClick={option.onClick ? option.onClick : () => {}}
                    key={index}
                  >
                    {option.text}
                  </li>
                )
              )}
            </ul>
          </nav>
        </>
      ) : (
        <nav className="center">
          <ul className="flex-row">
            {options.map((option, index) =>
              option.link ? (
                <Link to={option.link} key={index}>
                  <li
                    className={`navbar-item${
                      option.active ? "-active" : ""
                    } px-5`}
                  >
                    {option.text}
                  </li>
                </Link>
              ) : (
                <li
                  className={`navbar-item${
                    option.active ? "-active" : ""
                  } px-5`}
                  onClick={option.onClick ? option.onClick : () => {}}
                  key={index}
                >
                  {option.text}
                </li>
              )
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
