import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

export const modalState = createSlice({
  name: "modalState",
  initialState: {
    visible: false,
  },
  reducers: {
    openModal: (state, action) => {
      return { ...action.payload, visible: true };
    },
    closeModal: (state) => {
      return { visible: false };
    },
  },
});

// Action creators are generated for each case reducer function
export default modalState.reducer;
export const { openModal, closeModal } = modalState.actions;

export const useModal = () => {
  const dispatch = useDispatch();
  return {
    open: (modal) => {
      dispatch(openModal(modal));
    },
    close: () => {
      dispatch(closeModal());
    },
    switch: (modal) => {
      dispatch(closeModal());
      setTimeout(() => {
        dispatch(openModal(modal));
      }, 250 + 50);
    },
  };
};
