import React, { useState, useRef } from "react";
import { Button, Carousel } from "antd";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  MedicineBoxOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import DatePicker, { isSameDay } from "../datepicker/DatePicker";
import { useWindowWidth } from "../../redux/windowReducer";
import { useModal } from "../../redux/modalReducer";
import TimePicker from "../datepicker/TimePicker";
import FormModal from "./FormModal";
import _URL from "../../url/URL";

export default function DoctorCard({ doctor }) {
  const availableDates = doctor.availableDates.map((date) => new Date(date));
  const [currentDate, setCurrentDate] = useState(
    availableDates.length ? availableDates[0] : new Date()
  );
  const windowWidth = useWindowWidth();
  const carouselRef = useRef(null);
  const modal = useModal();

  const handleTimePicker = (event) => {
    modal.open({
      title: "Ustal szczegóły wizyty",
      content: (
        <FormModal
          date={currentDate.toLocaleDateString()}
          time={event.target.innerText}
          appointments={doctor.appointments}
          doctor={doctor.id}
        />
      ),
    });
  };

  const handleDuration = (rawDuration) => {
    try {
      const duration = rawDuration.split(":").map((dur) => parseInt(dur));
      if (duration[0]) {
        if (duration[0] === 1)
          return `{1 godzina ${duration[1] && `${duration[1]} minut`}}`;
        return `{${duration[0]} godziny ${
          duration[1] && `${duration[1]} minut`
        }}`;
      }
      return `${duration[1]} minut`;
    } catch {
      return rawDuration;
    }
  };

  return (
    <div className="container">
      {windowWidth < 768 ? (
        <div className="card card-sm">
          <div className="center-col">
            <img
              className="image"
              src={_URL + doctor.picture}
              alt={`${doctor.name} ${doctor.surname} ${doctor.specialisation} ${doctor.city}`}
            />
            <h2 className="m-0 pt-5 ml-10">
              {`${doctor.name} ${doctor.surname}`}
            </h2>
          </div>
          <h3 className="m-0 pt-5">{doctor.specialisation}</h3>
          {doctor.description && (
            <p className="m-0 pt-5">{doctor.description}</p>
          )}
          <hr className="divider" />
          <Carousel
            infinite={false}
            slidesToShow={1}
            draggable={true}
            dots={false}
            style={{ overflow: "hidden" }}
            ref={carouselRef}
          >
            <div>
              <div className="card-info">
                <div className="center-col">
                  <PhoneOutlined className="card-icon" />
                  {doctor.phone}
                </div>
                <div className="center-col">
                  <EnvironmentOutlined className="card-icon" />
                  {`${doctor.city}, ${doctor.address}`}
                </div>
                {doctor.appointments.length > 0 && (
                  <>
                    <div className="center-col">
                      <MedicineBoxOutlined className="card-icon" />
                      {doctor.appointments.length === 1
                        ? `${doctor.appointments[0].type}, ${doctor.appointments[0].price}zł`
                        : (doctor.appointments.length < 10 ||
                            doctor.appointments.length > 20) &&
                          doctor.appointments.length % 10 > 1 &&
                          doctor.appointments.length % 10 < 5
                        ? `${doctor.appointments.length} rodzaje wizyt od ${doctor.appointments[0].price}zł`
                        : `${doctor.appointments.length} rodzajów wizyt od ${doctor.appointments[0].price}zł`}
                    </div>
                    <div className="center-col">
                      <ClockCircleOutlined className="card-icon" />
                      {handleDuration(doctor.duration)}
                    </div>
                  </>
                )}
                <div className="center-row">
                  <Button
                    className="primary-button"
                    onClick={() => carouselRef.current.next()}
                  >
                    Zobacz wolne terminy
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <DatePicker
                date={currentDate}
                onDateChanged={setCurrentDate}
                availableDates={availableDates}
              />
              <TimePicker
                availableTimes={availableDates.filter((date) =>
                  isSameDay(currentDate, date)
                )}
                handleClick={handleTimePicker}
              />{" "}
            </div>
          </Carousel>
        </div>
      ) : (
        <div
          className="card card-lg flex-row"
          style={{ minHeight: doctor.description ? "305px" : "" }}
        >
          <div className="w-250">
            <div className="center-col">
              <img
                className="image"
                src={_URL + doctor.picture}
                alt={`${doctor.name} ${doctor.surname} ${doctor.specialisation} ${doctor.city}`}
              />
              <h2 className="m-0 pt-5 ml-10">
                {`${doctor.name} ${doctor.surname}`}
              </h2>
            </div>
            <div className="card-info-lg w-250">
              <h3 className="m-0 pt-5">{doctor.specialisation}</h3>
              {doctor.description && (
                <p
                  className="m-0 pt-5"
                  style={{ maxHeight: "45px", overflow: "hidden" }}
                >
                  {doctor.description}
                </p>
              )}
              <div className="center-col pt-5">
                <PhoneOutlined className="card-icon" />
                {doctor.phone}
              </div>
              <div className="center-col pt-5">
                <EnvironmentOutlined className="card-icon" />
                {`${doctor.city}, ${doctor.address}`}
              </div>
              {doctor.appointments.length > 0 && (
                <>
                  <div className="center-col pt-5">
                    <MedicineBoxOutlined className="card-icon" />
                    {doctor.appointments.length === 1
                      ? `${doctor.appointments[0].type}, ${doctor.appointments[0].price}zł`
                      : (doctor.appointments.length < 10 ||
                          doctor.appointments.length > 20) &&
                        doctor.appointments.length % 10 > 1 &&
                        doctor.appointments.length % 10 < 5
                      ? `${doctor.appointments.length} rodzaje wizyt od ${doctor.appointments[0].price}zł`
                      : `${doctor.appointments.length} rodzajów wizyt od ${doctor.appointments[0].price}zł`}
                  </div>
                  <div className="center-col pt-5">
                    <ClockCircleOutlined className="card-icon" />
                    {handleDuration(doctor.duration)}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="divider-vertical" />
          <div className="center text-center w-250">
            <div style={{ width: "100%" }}>
              <h4 className="m-0 p-10">Terminy wizyt</h4>
              <DatePicker
                date={currentDate}
                onDateChanged={setCurrentDate}
                availableDates={availableDates}
              />
              <TimePicker
                availableTimes={availableDates.filter((date) =>
                  isSameDay(currentDate, date)
                )}
                handleClick={handleTimePicker}
              />{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
