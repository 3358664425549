import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";

import { useModal } from "../../redux/modalReducer";

import { LOGIN_URL, useRequest } from "../../url/URL";
import axios from "axios";
import ResetPasswordModal from "./ResetPasswordModal";

export default function LoginModal({ navigateTo = "profil" }) {
  const navigate = useNavigate();
  const modal = useModal();
  const sendForm = useRequest({
    method: "POST",
    url: LOGIN_URL,
    on200: (response) => {
      axios.defaults.headers.common["Authorization"] =
        "Token " + response.data.token;
      window.localStorage.setItem("token", response.data.token);
      navigate(navigateTo);
    },
  });

  return (
    <Form onFinish={sendForm}>
      <Form.Item
        label="Email"
        name="username"
        rules={[{ required: true, message: "Podaj adres email!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>
      <Form.Item
        label="Hasło"
        name="password"
        rules={[{ required: true, message: "Podaj hasło!" }]}
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <div
        onClick={() => {
          modal.switch({
            title: "Przypomnij hasło",
            content: <ResetPasswordModal />,
          });
        }}
        style={{ color: "blue", cursor: "pointer" }}
      >
        Nie pamiętasz hasła?
      </div>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button className="primary-button w-100" htmlType="submit">
          Zaloguj się
        </Button>
      </div>
    </Form>
  );
}
