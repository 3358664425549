import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import SuccessModal from "../appointment/SuccessModal";
import AppointmentModal from "./AppointmentModal";
import LoginModal from "../account/LoginModal";
import EditMenu from "./EditMenu";
import NavBar from "../NavBar";

import { isSameDay } from "../datepicker/DatePicker";
import { useWindowWidth } from "../../redux/windowReducer";
import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import {
  CALENDAR_URL,
  CALENDAR_APPOINTMENT_URL,
  LOGOUT_URL,
  useRequest,
} from "../../url/URL";
import axios from "axios";

const { Content } = Layout;

export default function CalendarView() {
  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [workdays, setWorkdays] = useState([]);
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();
  const menuRef = useRef(null);
  const [menuState, setMenuState] = useState({
    visible: false,
    positionX: 0,
    positionY: 0,
  });
  const logout = useRequest({
    method: "POST",
    url: LOGOUT_URL,
    on200: () => {
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.removeItem("token");
      navigate("/");
    },
  });

  useEffect(() => {
    function handleClickOutside(event) {
      const menuButton = document.getElementsByClassName("fc-edit-button")[0];
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !menuButton.contains(event.target)
      ) {
        setMenuState((prevState) => {
          return {
            ...prevState,
            visible: false,
          };
        });
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const appendLeadingZeroes = (n) => (n <= 9 ? "0" + n : n);
  const getCalendar = () => {
    axios
      .get(CALENDAR_URL)
      .then((response) => {
        setCalendar(response.data.events);
        setAvailableDates(
          response.data.availableDates.map((date) => new Date(date))
        );
        setAppointmentInfo(response.data.appointmentInfo);
        setWorkdays(response.data.workdays);
        setHolidays(response.data.holidays);
        response.data.holidays.forEach((holiday) => {
          let date = holiday.date.split(".");
          date = new Date(date[2], date[1], date[0]);
          date =
            date.getFullYear().toString() +
            "-" +
            appendLeadingZeroes(date.getMonth()).toString() +
            "-" +
            appendLeadingZeroes(date.getDate()).toString();

          const dayCell = document.querySelector(`[data-date^="${date}"]`);
          dayCell.firstChild.style.backgroundColor = "LightGreen";
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/");
          modal.open({
            title: "Zaloguj się",
            content: <LoginModal navigateTo="kalendarz" />,
          });
        } else {
          setCalendar(null);
          setAvailableDates([]);
          modal.open({ content: <SuccessModal success={"error"} /> });
        }
      });
  };

  useEffect(() => {
    refresh.setFcn(getCalendar);
    refresh.setIntv(600000);
    return refresh.clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEventClick = async (info) => {
    const appointmentData = await axios.get(
      CALENDAR_APPOINTMENT_URL + "/" + info.event.id
    );
    modal.open({
      title: "Szczegóły wizyty",
      content: (
        <AppointmentModal
          appointment={appointmentData.data}
          availableDates={availableDates}
        />
      ),
    });
  };

  const navbarOptions = [
    { text: "kalendarz", active: true },
    { text: "profil", link: "/profil" },
    { text: "wyloguj się", onClick: () => logout() },
  ];

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        <div className={windowWidth > 768 ? "center-row" : ""}>
          <div
            className="card mt-20"
            style={{
              width: windowWidth > 768 ? "100%" : "auto",
              maxWidth: "1000px",
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              locale="pl"
              firstDay="1"
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
              }}
              dayMaxEvents={true}
              events={calendar}
              customButtons={{
                edit: {
                  text: "Edytuj",
                  click: function (event) {
                    setMenuState((prevState) => {
                      return {
                        visible: !menuState.visible,
                        positionX: event.target.offsetLeft - 40,
                        positionY: event.target.offsetTop + 40,
                      };
                    });
                  },
                },
              }}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek edit",
              }}
              buttonText={{
                today: "dziś",
                month: windowWidth > 768 ? "miesiąc" : "msc",
                week: windowWidth > 768 ? "tydzień" : "tydz",
              }}
              moreLinkText={windowWidth > 768 ? "więcej" : ""}
              allDaySlot={false}
              eventClick={handleEventClick}
              dayCellDidMount={(info) => {
                if (
                  (info.date.getDay() === 0 || info.date.getDay() === 6) &&
                  !info.isToday
                )
                  info.el.firstChild.style.backgroundColor = "LightGray";
                holidays.forEach((holiday) => {
                  let date = holiday.date.split(".");
                  date = new Date(date[2], date[1] - 1, date[0]);
                  if (isSameDay(date, info.date))
                    info.el.firstChild.style.backgroundColor = "LightGreen";
                });
              }}
            />
            {menuState.visible && (
              <div ref={menuRef}>
                <EditMenu
                  menuState={menuState}
                  availableDates={availableDates}
                  appointments={appointmentInfo}
                  holidays={holidays}
                  workdays={workdays}
                  hideMenu={() =>
                    setMenuState((prevState) => {
                      return {
                        ...prevState,
                        visible: false,
                      };
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
}
