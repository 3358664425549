import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

export const alertState = createSlice({
  name: "alertState",
  initialState: {
    id: 0,
    visible: false,
  },
  reducers: {
    triggerAlert: (state, action) => {
      return {
        ...action.payload,
        id: state.id + 1,
        visible: true,
        className: "alert-show",
      };
    },
    hideAlert: (state, action) => {
      if (action.payload === state.id)
        return { ...state, className: "alert-hide" };
      else return state;
    },
    destroyAlert: (state, action) => {
      if (action.payload === state.id) return { id: state.id, visible: false };
      else return state;
    },
  },
});

// Action creators are generated for each case reducer function
export default alertState.reducer;
export const { triggerAlert, hideAlert, destroyAlert } = alertState.actions;

export const useAlert = () => {
  const alertState = useSelector((state) => state.alertState);
  const dispatch = useDispatch();

  return (alert) => {
    if (!alertState.visible) {
      dispatch(triggerAlert(alert));
      setTimeout(() => {
        dispatch(hideAlert(alertState.id + 1));
      }, 3000);
      setTimeout(() => {
        dispatch(destroyAlert(alertState.id + 1));
      }, 3500);
    } else {
      dispatch(hideAlert(alertState.id));
      setTimeout(() => {
        dispatch(triggerAlert(alert));
      }, 500);
      setTimeout(() => {
        dispatch(hideAlert(alertState.id + 1));
      }, 3500);
      setTimeout(() => {
        dispatch(destroyAlert(alertState.id + 1));
      }, 4000);
    }
  };
};
