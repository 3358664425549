import React from "react";
import { Button } from "antd";

export default function TimePicker({
  availableTimes,
  handleClick,
  theme = "primary",
}) {
  return (
    <>
      {availableTimes.length ? (
        <div className="timepicker">
          {availableTimes.map((time, index) => (
            <Button
              className={`${theme}-button w-53 mt-5`}
              key={index}
              onClick={handleClick}
            >{`${time.getHours()}:${time.getMinutes() || "00"}`}</Button>
          ))}
        </div>
      ) : (
        <div className="center pt-5">Brak wolnych terminów</div>
      )}
    </>
  );
}
