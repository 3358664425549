import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import NavBar from "../NavBar";
import ProfileCard from "./ProfileCard";
import LoginModal from "../account/LoginModal";
import SuccessModal from "../appointment/SuccessModal";

import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import { PROFILE_URL, LOGOUT_URL, useRequest } from "../../url/URL";
import axios from "axios";

const { Content } = Layout;

export default function ProfileView({ isCallback = false }) {
  const [loadingSwitch, setLoadingSwitch] = useState(isCallback);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();
  const logout = useRequest({
    method: "POST",
    url: LOGOUT_URL,
    on200: () => {
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.removeItem("token");
      navigate("/");
    },
  });

  const handleGoogleCalendar = useRequest({
    method: "GET",
    url: window.location.href.replace("profil", "api/profile"),
    after: () => {
      navigate("/profil");
      getProfile();
      setLoadingSwitch(false);
    },
  });

  const getProfile = (isCallback = false) => {
    axios
      .get(PROFILE_URL)
      .then((response) => {
        if (isCallback)
          response.data.doctor.googlecalendar =
            !response.data.doctor.googlecalendar;
        setProfile(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/");
          modal.open({ title: "Zaloguj się", content: <LoginModal /> });
        } else {
          setProfile(null);
          modal.open({ content: <SuccessModal success={"error"} /> });
        }
      });
  };

  useEffect(() => {
    refresh.setFcn(() => getProfile(isCallback));
    if (isCallback) handleGoogleCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallback]);

  const navbarOptions = [
    { text: "kalendarz", link: "/kalendarz" },
    { text: "profil", active: true },
    { text: "wyloguj się", onClick: () => logout() },
  ];

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        {profile && (
          <ProfileCard
            profile={profile}
            loadingSwitch={loadingSwitch}
            setLoadingSwitch={setLoadingSwitch}
          />
        )}
      </Content>
    </Layout>
  );
}
