import axios from "axios";
import { useModal } from "../redux/modalReducer";
import { useAlert } from "../redux/alertReducer";

const _URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : window.location.origin;

export const HOME_URL = _URL + "/api/home";
export const DOCTORS_URL = _URL + "/api/doctors";
export const APPOINTMENT_URL = _URL + "/api/appointment/";
export const NEW_APPOINTMENT_URL = _URL + "/api/appointment/new/";
export const PATIENT_URL = _URL + "/api/patient";
export const LOGIN_URL = _URL + "/api/login";
export const SIGNUP_URL = _URL + "/api/signup";
export const CONFIRM_URL = _URL + "/api/confirm/";
export const RESET_PASSWORD_URL = _URL + "/api/reset";
export const LOGOUT_URL = _URL + "/api/logout";
export const PROFILE_URL = _URL + "/api/profile";
export const CHANGEPASSWORD_URL = _URL + "/api/profile/change_password";
export const WORKDAY_URL = _URL + "/api/workday/";
export const APPOINTMENTTYPE_URL = _URL + "/api/type/";
export const CALENDAR_URL = _URL + "/api/calendar";
export const CALENDAR_APPOINTMENT_URL = _URL + "/api/calendar/appointment";
export const HOLIDAY_URL = _URL + "/api/calendar/holiday";
export default _URL;

export const useRequest = ({
  method,
  url,
  before = (data) => {},
  on200 = (response) => {},
  on203 = (response) => {},
  onError = (error) => {},
  after = () => {},
  alertOn200 = true,
  alertOn203 = true,
  alertOnError = true,
  closeModalOn200 = true,
  closeModalOn203 = false,
  closeModalOnError = false,
}) => {
  const modal = useModal();
  const alert = useAlert();

  let request;
  switch (method) {
    case "GET":
      request = axios.get;
      break;
    case "POST":
      request = axios.post;
      break;
    case "PUT":
      request = axios.put;
      break;
    case "PATCH":
      request = axios.patch;
      break;
    case "DELETE":
      request = axios.delete;
      break;
    default:
      request = () => {};
  }

  return (data = {}, newUrl) => {
    before(data);
    request(newUrl ? newUrl : url, data)
      .then((response) => {
        if (response.status === 200) {
          on200(response, data);
          if (closeModalOn200) modal.close();
          if (alertOn200)
            alert({
              type: response.data.alert,
              message: response.data.message,
            });
        } else if (response.status === 203) {
          on203(response);
          if (closeModalOn203) modal.close();
          if (alertOn203)
            alert({
              type: response.data.alert,
              message: response.data.message,
            });
        }
      })
      .catch((error) => {
        onError(error);
        if (closeModalOnError) modal.close();
        if (alertOnError)
          alert({ type: "warning", message: "Coś poszło nie tak!" });
      })
      .then(() => {
        after();
      });
  };
};
