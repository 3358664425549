import { useState } from "react";
import { Button } from "antd";
import {
  PhoneOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  UserOutlined,
  FormOutlined,
} from "@ant-design/icons";
import DatePicker, { isSameDay } from "../datepicker/DatePicker";
import TimePicker from "../datepicker/TimePicker";
import ConfirmModalContent from "../modal/ConfirmModalContent";

import { CALENDAR_APPOINTMENT_URL, useRequest } from "../../url/URL";
import { useModal } from "../../redux/modalReducer";
import { useRefresh } from "../../redux/refreshReducer";

export default function AppointmentModal({ appointment, availableDates }) {
  const refresh = useRefresh();
  const modal = useModal();

  const cancelAppointment = useRequest({
    method: "DELETE",
    url: CALENDAR_APPOINTMENT_URL + "/" + appointment.id,
    on200: refresh.run,
    closeModalOn203: true,
    closeModalOnError: true,
  });
  const postponeAppointment = useRequest({
    method: "PUT",
    url: CALENDAR_APPOINTMENT_URL + "/" + appointment.id,
    on200: refresh.run,
    closeModalOn203: true,
    closeModalOnError: true,
  });

  const handleCancel = () => {
    modal.switch({
      title: "Szczegóły wizyty",
      content: (
        <AppointmentModal
          appointment={appointment}
          availableDates={availableDates}
        />
      ),
    });
  };

  const PostponeModal = () => {
    const [currentDate, setCurrentDate] = useState(
      availableDates.length ? availableDates[0] : new Date()
    );
    return (
      <>
        <DatePicker
          date={currentDate}
          onDateChanged={setCurrentDate}
          availableDates={availableDates}
        />
        <TimePicker
          availableTimes={availableDates.filter((date) =>
            isSameDay(currentDate, date)
          )}
          handleClick={(event) => {
            modal.switch({
              title: "Czy na pewno chcesz przełożyć wizytę?",
              content: (
                <ConfirmModalContent
                  text={`Nowy termin: ${currentDate.toLocaleDateString()} ${
                    event.target.innerText
                  }`}
                  onCancel={handleCancel}
                  onOk={() =>
                    postponeAppointment({
                      date: `${currentDate.toLocaleDateString()} ${
                        event.target.innerText
                      }`,
                    })
                  }
                />
              ),
            });
          }}
          theme="secondary"
        />{" "}
      </>
    );
  };

  return (
    <>
      <div className="center-col pt-5">
        <UserOutlined className="card-icon" />
        <h3 className="m-0 pt-5">
          {`${appointment.name} ${appointment.surname}`}
        </h3>
      </div>
      <div className="center-col pt-5">
        <PhoneOutlined className="card-icon" />
        {appointment.phone}
      </div>
      <div className="center-col pt-10">
        <ScheduleOutlined className="card-icon" />
        {appointment.date}
      </div>
      <div className="center-col pt-10">
        <MedicineBoxOutlined className="card-icon" />
        {`${appointment.type}, ${appointment.price}zł`}
      </div>
      {appointment.description && (
        <div className="center-col pt-10">
          <FormOutlined className="card-icon" />
          {appointment.description}
        </div>
      )}
      <div className="center-row pt-10">
        <Button
          className="danger-button w-120"
          onClick={() => {
            modal.switch({
              title: "Czy na pewno chcesz odwołać wizytę?",
              content: (
                <ConfirmModalContent
                  onCancel={handleCancel}
                  onOk={cancelAppointment}
                  theme="danger"
                />
              ),
            });
          }}
        >
          Odwołaj wizytę
        </Button>
        <Button
          className="secondary-button w-120"
          onClick={() => {
            modal.switch({
              title: "Wybierz nową datę wizyty",
              content: <PostponeModal />,
            });
          }}
        >
          Przełóż wizytę
        </Button>
      </div>
    </>
  );
}
