import React from "react";
import { Form, Input, Button } from "antd";

import { useModal } from "../../redux/modalReducer";

import { CHANGEPASSWORD_URL, useRequest } from "../../url/URL";

export default function ChangePasswordModal() {
  const modal = useModal();
  const [form] = Form.useForm();
  const sendForm = useRequest({
    method: "PUT",
    url: CHANGEPASSWORD_URL,
    after: form.resetFields,
  });
  const initialValues = {
    old_password: null,
    new_password1: null,
    new_password2: null,
  };
  return (
    <Form
      form={form}
      onFinish={sendForm}
      validateTrigger="onSubmit"
      initialValues={initialValues}
    >
      <Form.Item
        label="Stare hasło"
        name="old_password"
        rules={[{ required: true, message: "Podaj bieżące hasło!" }]}
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Nowe hasło"
        name="new_password1"
        rules={[
          { required: true, message: "Podaj hasło!" },
          { min: 8, message: "Hasło powinno mieć przynajmniej 8 znaków." },
        ]}
        validateFirst
        help={
          <ul style={{ listStyleType: "disc", paddingLeft: "10px" }}>
            <li key="1">
              Twoje hasło nie może być zbyt podobne do twoich innych danych
              osobistych.
            </li>
            <li key="2"> Twoje hasło musi zawierać co najmniej 8 znaków.</li>{" "}
            <li key="3">
              Twoje hasło nie może być powszechnie używanym hasłem.
            </li>{" "}
            <li key="4"> Twoje hasło nie może składać się tylko z cyfr.</li>
          </ul>
        }
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Powtórz nowe hasło"
        name="new_password2"
        validateFirst
        rules={[
          {
            required: true,
            message: "Powtórz hasło!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("new_password1") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Podane hasła nie są takie same!");
            },
          }),
        ]}
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button className="primary-button w-100" htmlType="submit">
          Zapisz
        </Button>
      </div>
    </Form>
  );
}
