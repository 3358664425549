import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Layout, Form, Button, Select } from "antd";
import {
  FieldTimeOutlined,
  SmileOutlined,
  MobileOutlined,
  CommentOutlined,
  CloudSyncOutlined,
  GlobalOutlined,
  GiftOutlined,
  SearchOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";

import NavBar from "../NavBar";
import LoginModal from "../account/LoginModal";
import SignupModal from "../account/SignupModal";
import PatientModal from "../doctors/PatientModal";

import { useWindowWidth } from "../../redux/windowReducer";
import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import _URL, { HOME_URL, DOCTORS_URL } from "../../url/URL";
import axios from "axios";

const { Content } = Layout;

export default function HomeView() {
  const [doctors, setDoctors] = useState(null);
  const [data, setData] = useState(null);
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();
  const getData = () => {
    axios
      .get(HOME_URL)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  };
  const getDoctors = () => {
    axios
      .get(DOCTORS_URL)
      .then((response) => {
        setDoctors(response.data.doctors);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    refresh.setFcn(() => {
      getData();
      getDoctors();
    });
    refresh.setIntv(600000);
    return refresh.clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (values) => {
    Object.keys(values).forEach(
      (key) => values[key] === undefined && delete values[key]
    );
    navigate({
      pathname: "lekarze",
      search: createSearchParams(values).toString(),
    });
  };

  const navbarOptions = [
    { text: "strona główna", active: true },
    {
      text: "twoja wizyta",
      onClick: () => {
        modal.open({ title: "Podaj dane wizyty", content: <PatientModal /> });
      },
    },
    {
      text: "zaloguj się",
      onClick: () => {
        modal.open({ title: "Zaloguj się", content: <LoginModal /> });
      },
    },
    {
      text: "zarejestruj się",
      onClick: () => {
        if (data && data.signup) {
          modal.open({
            title: "Zarejestruj się",
            content: (
              <SignupModal
                specialisations={data.signup.specialisations}
                durations={data.signup.durations}
                cities={data.signup.cities}
              />
            ),
          });
        }
      },
    },
  ];

  const cardProps = {
    className: `card card-${windowWidth < 768 ? "sm" : "lg"}`,
    style: { width: `${windowWidth < 768 ? "" : "580px"}` },
  };

  const Item = ({ Icon, text, lastItem = false }) => <></>;

  const SmallCardItem = ({ Icon, text, lastItem = false }) => (
    <div className={`flex ${lastItem ? "" : "my-10"}`}>
      <div className="mx-10 center-col">
        <Icon style={{ fontSize: "40px" }} />
      </div>
      {text}
    </div>
  );

  const LargeCardItem = ({ Icon, text }) => (
    <div className="mr-5">
      <Icon className="mb-5" style={{ fontSize: "40px" }} />
      <br />
      {text}
    </div>
  );

  const ItemsWrapper = ({ children }) =>
    windowWidth < 768 ? (
      <div className="text-center">
        {children.map((child, id) => (
          <SmallCardItem {...child.props} key={id} />
        ))}
      </div>
    ) : (
      <div
        className="flex center text-center pt-10"
        style={{ alignItems: "start" }}
      >
        {children.map((child, id) => (
          <LargeCardItem {...child.props} key={id} />
        ))}
      </div>
    );

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        <div className="container">
          <div {...cardProps}>
            <h2
              className="m-0 pb-5 mb-5 text-center"
              style={{ borderBottom: "1px solid var(--secondary-color)" }}
            >
              Znajdź swojego lekarza
            </h2>
            <Form onFinish={search}>
              <Form.Item label="Miasto" name="miasto">
                <Select
                  showSearch
                  optionFilterProp="label"
                  filterOption={(value, option) =>
                    option.label.toLowerCase().startsWith(value.toLowerCase())
                  }
                  notFoundContent={
                    <div className="ant-select-no-content">Brak wyników</div>
                  }
                  options={
                    data && data.cities
                      ? data.cities.map((city) => {
                          return { value: city, label: city };
                        })
                      : []
                  }
                />
              </Form.Item>

              <Form.Item label="Specjalizacja" name="specjalizacja">
                <Select
                  showSearch
                  optionFilterProp="label"
                  filterOption={(value, option) =>
                    option.label.toLowerCase().startsWith(value.toLowerCase())
                  }
                  notFoundContent={
                    <div className="ant-select-no-content">Brak wyników</div>
                  }
                  options={
                    data && data.specialisations
                      ? data.specialisations.map((specialisation) => {
                          return {
                            value: specialisation,
                            label: specialisation,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>

              <div className="center-row pt-10">
                <Button
                  className="primary-button w-100"
                  htmlType="submit"
                  icon={<SearchOutlined className="mr-5" />}
                >
                  Szukaj
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="container">
          <div {...cardProps}>
            <h2
              className="m-0 pb-5 mb-5 text-center"
              style={{ borderBottom: "1px solid var(--secondary-color)" }}
            >
              Dlaczego pacjenci wybierają idedolekarza?
            </h2>
            <ItemsWrapper>
              <Item
                Icon={FieldTimeOutlined}
                text="Umówisz się w mniej niż minutę"
              />
              <Item Icon={SmileOutlined} text="Nie musisz zakładać konta" />
              <Item
                Icon={MobileOutlined}
                text="Łatwy dostęp do wizyty jednym kliknięciem"
                lastItem
              />
            </ItemsWrapper>
          </div>
        </div>
        <div className="container">
          <div {...cardProps}>
            <h2
              className="m-0 pb-5 mb-5 text-center"
              style={{ borderBottom: "1px solid var(--secondary-color)" }}
            >
              Zobacz popularnych lekarzy
            </h2>
            {doctors &&
              doctors.map((doctor) => (
                <div
                  className="py-5"
                  style={{ borderBottom: "1px solid var(--secondary-color)" }}
                  key={doctor.id}
                >
                  <div
                    className="center-col"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="center-col">
                      <img
                        className="image"
                        src={_URL + doctor.picture}
                        alt={`${doctor.name} ${doctor.surname} ${doctor.specialisation} ${doctor.city}`}
                      />
                      <div>
                        <h3 className="m-0 pt-5 ml-10">
                          {`${doctor.name} ${doctor.surname}`}
                        </h3>
                        <div className="m-0 ml-10">{doctor.specialisation}</div>
                        <div className="m-0 ml-10">{doctor.city}</div>
                      </div>
                    </div>
                    <Button
                      className="doctor-button"
                      icon={<RightSquareOutlined />}
                      onClick={() => {
                        navigate({
                          pathname: "lekarze",
                          search: createSearchParams({
                            imie: doctor.name,
                            nazwisko: doctor.surname,
                          }).toString(),
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <div {...cardProps}>
            <h2
              className="m-0 pb-5 mb-5 text-center"
              style={{ borderBottom: "1px solid var(--secondary-color)" }}
            >
              Dlaczego lekarze wybierają idedolekarza?
            </h2>
            <ItemsWrapper>
              <Item
                Icon={CommentOutlined}
                text="Dostaniesz powiadomienia SMS o każdej wizycie"
              />
              <Item
                Icon={CloudSyncOutlined}
                text="Możesz połączyć z kalendarzem w telefonie"
              />
              <Item
                Icon={GlobalOutlined}
                text="Możesz korzystać z API na swojej stronie internetowej"
              />
              <Item
                Icon={GiftOutlined}
                text="Otrzymasz pierwsze 3 miesiące za darmo"
                lastItem
              />
            </ItemsWrapper>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
