import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import NavBar from "../NavBar";
import DoctorCard from "./DoctorCard";
import LoginModal from "../account/LoginModal";
import SignupModal from "../account/SignupModal";
import PatientModal from "./PatientModal";

import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import { useAlert } from "../../redux/alertReducer";
import { DOCTORS_URL } from "../../url/URL";
import axios from "axios";

const { Content } = Layout;

export default function DoctorsView() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();
  const alert = useAlert();
  const getDoctors = () => {
    axios
      .get(DOCTORS_URL + window.location.search)
      .then((response) => {
        if (response && response.status === 200) {
          setData(response.data);
        } else {
          navigate("/");
          alert({
            type: response.data.alert,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    refresh.setFcn(getDoctors);
    refresh.setIntv(600000);
    return refresh.clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navbarOptions = [
    { text: "strona główna", link: "/" },
    {
      text: "twoja wizyta",
      onClick: () => {
        modal.open({ title: "Podaj dane wizyty", content: <PatientModal /> });
      },
    },
    {
      text: "zaloguj się",
      onClick: () => {
        modal.open({ title: "Zaloguj się", content: <LoginModal /> });
      },
    },
    {
      text: "zarejestruj się",
      onClick: () => {
        modal.open({
          title: "Zarejestruj się",
          content: (
            <SignupModal
              specialisations={data.specialisations}
              durations={data.durations}
              cities={data.cities}
            />
          ),
        });
      },
    },
  ];

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        {data &&
          data.doctors &&
          data.doctors.map((doctor) => (
            <DoctorCard doctor={doctor} key={doctor.id} />
          ))}
      </Content>
    </Layout>
  );
}
