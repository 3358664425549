import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Animate from "rc-animate";
import { Alert } from "antd";

import ConfirmAccountView from "./components/account/ConfirmAccountView";
import ResetPasswordView from "./components/account/ResetPasswordView";
import AppointmentView from "./components/appointment/AppointmentView";
import CalendarView from "./components/calendar/CalendarView";
import DoctorsView from "./components/doctors/DoctorsView";
import ProfileView from "./components/profile/ProfileView";
import ModalBase from "./components/modal/ModalBase";
import HomeView from "./components/home/HomeView";

import { useSetWindowWidth } from "./redux/windowReducer";
import axios from "axios";

if (window.localStorage.getItem("token"))
  axios.defaults.headers.common["Authorization"] =
    "Token " + window.localStorage.getItem("token");

export default function App() {
  const modalState = useSelector((state) => state.modalState);
  const alertState = useSelector((state) => state.alertState);
  const setWindowWidth = useSetWindowWidth();

  useEffect(() => {
    if (modalState.visible) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [modalState.visible]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="" element={<HomeView />} />
          <Route path="lekarze" element={<DoctorsView />} />
          <Route exact path="wizyta/:token" element={<AppointmentView />} />
          <Route exact path="profil" element={<ProfileView />} />
          <Route
            exact
            path="profil/googlecalendar/oauth2callback/"
            element={<ProfileView isCallback />}
          />
          <Route exact path="kalendarz" element={<CalendarView />} />
          <Route
            exact
            path="potwierdz/:id/:token"
            element={<ConfirmAccountView />}
          />
          <Route
            exact
            path="reset/:id/:token"
            element={<ResetPasswordView />}
          />
        </Routes>

        {alertState.visible && (
          <div className="alert-wrapper">
            <Alert
              message={alertState.message}
              type={alertState.type}
              className={alertState.className}
            />
          </div>
        )}

        <Animate transitionName="fade" transitionAppear>
          {modalState.visible && (
            <ModalBase title={modalState.title} content={modalState.content} />
          )}
        </Animate>
      </BrowserRouter>
    </>
  );
}
