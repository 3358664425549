import React, { useState } from "react";
import PropTypes from "prop-types";

const THIS_YEAR = +new Date().getFullYear();
const THIS_MONTH = +new Date().getMonth() + 1;

const WEEK_DAYS = {
  Monday: "Pon",
  Tuesday: "Wt",
  Wednesday: "Śr",
  Thursday: "Czw",
  Friday: "Pt",
  Saturday: "Sob",
  Sunday: "Nd",
};

const CALENDAR_MONTHS = {
  January: "Styczeń",
  February: "Luty",
  March: "Marzec",
  April: "Kwiecień",
  May: "Maj",
  June: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Pażdziernik",
  November: "Listopad",
  December: "Grudzień",
};

const CALENDAR_WEEKS = 3;

const zeroPad = (value, length) => {
  return `${value}`.padStart(length, "0");
};

const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;
  return month === 2
    ? leapYear
      ? 29
      : 28
    : months30.includes(month)
    ? 30
    : 31;
};

const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() || 7;
};

const isDate = (date) => {
  const isDate = Object.prototype.toString.call(date) === "[object Date]";
  const isValidDate = date && !Number.isNaN(date.valueOf());

  return isDate && isValidDate;
};

// const isSameMonth = (date, basedate = new Date()) => {
//   if (!(isDate(date) && isDate(basedate))) return false;
//   const basedateMonth = +basedate.getMonth() + 1;
//   const basedateYear = basedate.getFullYear();
//   const dateMonth = +date.getMonth() + 1;
//   const dateYear = date.getFullYear();
//   return +basedateMonth === +dateMonth && +basedateYear === +dateYear;
// };

export const isSameDay = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false;
  const basedateDate = basedate.getDate();
  const basedateMonth = +basedate.getMonth() + 1;
  const basedateYear = basedate.getFullYear();
  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();
  return (
    +basedateDate === +dateDate &&
    +basedateMonth === +dateMonth &&
    +basedateYear === +dateYear
  );
};

const getDateISO = (date = new Date()) => {
  if (!isDate(date)) return null;
  return [
    date.getFullYear(),
    zeroPad(+date.getMonth() + 1, 2),
    zeroPad(+date.getDate(), 2),
  ].join("-");
};

const getPreviousMonth = (month, year) => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;
  return { month: prevMonth, year: prevMonthYear };
};

const getNextMonth = (month, year) => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;
  return { month: nextMonth, year: nextMonthYear };
};

export function addDays(date, days) {
  let result = new Date(date);
  result.setTime(result.getTime() + days * 24 * 60 * 60 * 1000);
  return result;
}

// const getWeekNumber = (date) => {
//   const _date = new Date(date);
//   const firstJan = new Date(TODAY.getFullYear(), 0, 1);
//   const numberOfDays = Math.floor((_date - firstJan) / (24 * 60 * 60 * 1000));
//   const weekNumber = Math.ceil((_date.getDay() + numberOfDays) / 7);
//   return weekNumber;
// };

// const getWeekFirstDay = (weekNumber) => {
//   const firstJan = new Date(TODAY.getFullYear(), 0, 1);
//   const firstWeekDay = addDays(
//     firstJan,
//     weekNumber * 7 - firstJan.getDay() + 1
//   );
//   return firstWeekDay;
// };

// Calendar builder for a month in the specified year
// Returns an array of the calendar dates.
// Each calendar date is represented as an array => [YYYY, MM, DD]
const CalendarBuilder = (weekDate, month = THIS_MONTH, year = THIS_YEAR) => {
  const day = weekDate.getDay() || 7;
  const date = weekDate.getDate();

  const monthFirstDay = getMonthFirstDay(month, year);
  const monthDays = getMonthDays(month, year);
  const daysFromPrevMonth = date <= 8 - monthFirstDay ? monthFirstDay - 1 : 0;
  const daysFromNextMonth =
    date + CALENDAR_WEEKS * 7 - day > monthDays
      ? date + CALENDAR_WEEKS * 7 - day - monthDays
      : 0;

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(
    month,
    year
  );
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);
  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });

  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);
  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  return [
    ...prevMonthDates,
    ...thisMonthDates.slice(
      date - day > 0 ? date - day : 0,
      CALENDAR_WEEKS * 7 + date - day < monthDays
        ? CALENDAR_WEEKS * 7 + date - day
        : monthDays
    ),
    ...nextMonthDates,
  ];
};

export default function DatePicker({ date, onDateChanged, availableDates }) {
  const availableDays = [
    ...new Set(availableDates.map((date) => date.toDateString())),
  ].map((date) => new Date(date));

  const [dateState, setDateState] = useState({
    current: date,
    weekDate: date,
    month: +date.getMonth() + 1,
    year: date.getFullYear(),
  });

  const getCalendarDates = () => {
    const { current, weekDate, month, year } = dateState;
    const calendarMonth = month || +current.getMonth() + 1;
    const calendarYear = year || current.getFullYear();
    return CalendarBuilder(weekDate, calendarMonth, calendarYear);
  };

  const gotoDate = (date) => (evt) => {
    evt && evt.preventDefault();
    const { current } = dateState;
    !(current && isSameDay(date, current)) &&
      setDateState({ ...dateState, current: date });
    onDateChanged(date);
  };

  // const gotoPreviousMonth = () => {
  //   const { month, year } = dateState;
  //   const previousMonth = getPreviousMonth(month, year);
  //   setDateState({
  //     month: previousMonth.month,
  //     year: previousMonth.year,
  //     current: dateState.current,
  //   });
  // };

  // const gotoNextMonth = () => {
  //   const { month, year } = dateState;
  //   const nextMonth = getNextMonth(month, year);
  //   setDateState({
  //     month: nextMonth.month,
  //     year: nextMonth.year,
  //     current: dateState.current,
  //   });
  // };

  const gotoPreviousWeek = () => {
    const newDate = addDays(dateState.weekDate, -7);
    setDateState({
      ...dateState,
      weekDate: newDate,
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const gotoNextWeek = () => {
    const newDate = addDays(dateState.weekDate, 7);
    setDateState({
      ...dateState,
      weekDate: newDate,
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const renderMonthAndYear = () => {
    const { month, year } = dateState;
    const monthname =
      Object.values(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <div className="calendar-header">
        <div
          className="arrow arrow-left"
          onClick={gotoPreviousWeek}
          title="Poprzedni tydzień"
        />
        <div className="calendar-month">
          {monthname} {year}
        </div>
        <div
          className="arrow arrow-right"
          onClick={gotoNextWeek}
          title="Następny tydzień"
        />
      </div>
    );
  };

  const renderDayLabel = (day, index) => {
    const daylabel = WEEK_DAYS[day].toUpperCase();
    return (
      <div className="calendar-day center" key={daylabel} index={index}>
        {daylabel}
      </div>
    );
  };

  const renderCalendarDate = (date, index) => {
    const { current } = dateState;
    const _date = new Date(date.join("-"));
    // const isToday = isSameDay(_date, TODAY);
    const isCurrent = current && isSameDay(_date, current);
    // const inMonth =
    //   month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));
    const isAvailable = !availableDays.every((day) => !isSameDay(_date, day));
    const onClick = isAvailable ? gotoDate(_date) : () => {};
    const props = { index, onClick, title: _date.toDateString() };
    const DateComponent = isCurrent
      ? "calendar-date highlighted-calendar-date center"
      : isAvailable
      ? "calendar-date center"
      : "calendar-date-unavailable center";
    return (
      <div className={DateComponent} key={getDateISO(_date)} {...props}>
        {_date.getDate()}
      </div>
    );
  };

  return (
    <>
      <div className="calendar-container">
        {renderMonthAndYear()}
        <div className="calendar-grid">
          <>{Object.keys(WEEK_DAYS).map(renderDayLabel)}</>
          <>{getCalendarDates().map(renderCalendarDate)}</>
        </div>
      </div>
    </>
  );
}
DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onDateChanged: PropTypes.func,
};
