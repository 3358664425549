import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";

import { useModal } from "../../redux/modalReducer";

import { SIGNUP_URL, useRequest } from "../../url/URL";

export default function SignupModal({
  specialisations = [],
  cities = [],
  durations = [],
}) {
  const [sending, setSending] = useState(false);
  const modal = useModal();
  const sendForm = useRequest({
    method: "POST",
    url: SIGNUP_URL,
    before: () => setSending(true),
    after: () => setSending(false),
  });

  return (
    <Form onFinish={sendForm}>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Podaj adres email!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Imię"
        name="name"
        rules={[{ required: true, message: "Podaj imię!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nazwisko"
        name="surname"
        rules={[{ required: true, message: "Podaj nazwisko!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Specjalizacja"
        name="specialisation"
        rules={[{ required: true, message: "Wybierz specjalizację!" }]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          filterOption={(value, option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
          }
          notFoundContent={
            <div className="ant-select-no-content">Brak wyników</div>
          }
          options={specialisations.map((specialisation) => {
            return {
              value: specialisation.id,
              label: specialisation.specialisation,
            };
          })}
        ></Select>
      </Form.Item>

      <Form.Item
        label="Numer telefonu"
        name="phone"
        rules={[
          { required: true, message: "Podaj swój numer telefonu!" },
          {
            validator: (rule, value) => {
              if (value < 100000000 || value > 999999999)
                return Promise.reject("Niepoprawny numer telefonu!");
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onBlur"
        validateFirst
      >
        <InputNumber
          className="addon-before"
          addonBefore="+48"
          controls={false}
        />
      </Form.Item>

      <Form.Item
        label="Miasto"
        name="city"
        rules={[{ required: true, message: "Wybierz miasto" }]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          filterOption={(value, option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
          }
          notFoundContent={
            <div className="ant-select-no-content">Brak wyników</div>
          }
          options={cities.map((city) => {
            return { id: city.id, value: city.id, label: city.city };
          })}
        ></Select>
      </Form.Item>

      <Form.Item
        label="Adres"
        name="address"
        rules={[{ required: true, message: "Podaj adres" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Czas trwania wizyty"
        name="duration"
        rules={[{ required: true, message: "Podaj czas trwania wizyty!" }]}
      >
        <Select
          options={durations.map((duration) => {
            return { value: duration.id, label: duration.duration };
          })}
        ></Select>
      </Form.Item>

      <Form.Item label="Opis" name="description">
        <Input.TextArea maxLength={1000} rows={3} style={{ resize: "none" }} />
      </Form.Item>

      <Form.Item
        label="Hasło"
        name="password1"
        rules={[
          { required: true, message: "Podaj hasło!" },
          { min: 8, message: "Hasło powinno mieć przynajmniej 8 znaków." },
        ]}
        validateFirst
        help={
          <ul style={{ listStyleType: "disc", paddingLeft: "10px" }}>
            <li key="1">
              Twoje hasło nie może być zbyt podobne do twoich innych danych
              osobistych.
            </li>
            <li key="2"> Twoje hasło musi zawierać co najmniej 8 znaków.</li>{" "}
            <li key="3">
              Twoje hasło nie może być powszechnie używanym hasłem.
            </li>{" "}
            <li key="4"> Twoje hasło nie może składać się tylko z cyfr.</li>
          </ul>
        }
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Powtórz hasło"
        name="password2"
        validateFirst
        rules={[
          {
            required: true,
            message: "Powtórz hasło!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password1") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Podane hasła nie są takie same!");
            },
          }),
        ]}
      >
        <Input.Password maxLength={100} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-120" onClick={modal.close}>
          Anuluj
        </Button>

        <Button
          className="primary-button w-120"
          htmlType="submit"
          loading={sending}
        >
          Zarejestruj się
        </Button>
      </div>
    </Form>
  );
}
