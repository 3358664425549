import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Result, Form, Button, InputNumber } from "antd";
import {
  CheckCircleFilled,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { useModal } from "../../redux/modalReducer";
import { useRefresh } from "../../redux/refreshReducer";

import { APPOINTMENT_URL, useRequest } from "../../url/URL";
import axios from "axios";

export default function ConfirmModal({ appointment }) {
  const [confirm, setConfirm] = useState("waiting");
  const [enterCode, setEnterCode] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();
  const sendForm = useRequest({
    method: "PATCH",
    url: APPOINTMENT_URL + appointment,
    on200: (response) => {
      if (response.data.token) {
        setConfirm("finish");
        setToken(response.data.token);
      }
    },
    on203: (response) => {
      if (response.data.message.inludes("termin")) setConfirm("error-date");
    },
    onError: () => {
      setConfirm("error");
    },
    after: refresh.run,
    alertOn200: false,
    closeModalOn200: false,
  });

  const getAppointment = () => {
    if (confirm === "waiting")
      axios
        .patch(APPOINTMENT_URL + appointment)
        .then((response) => {
          if (response.status === 200 && response.data.confirmed) {
            setConfirm("finish");
            setToken(response.data.token);
            refresh.run();
          } else if (
            response.status === 203 &&
            response.data.message.inludes("termin")
          ) {
            setConfirm("error-date");
            refresh.run();
          } else setConfirm("waiting");
        })
        .catch((error) => {
          setConfirm("error");
          refresh.run();
        });
  };

  useEffect(() => {
    const confirmRefresh = setInterval(getAppointment, 1000);
    return () => {
      clearInterval(confirmRefresh);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {confirm === "finish" && (
        <>
          <Result
            icon={<CheckCircleFilled style={{ color: "#52c41a" }} />}
            title="Potwierdzono wizytę!"
            subTitle="Możesz teraz przejść do swojej wizyty lub wrócić do strony głównej."
          />
          <div className="center-row pt-10">
            <Button className="secondary-button w-100" onClick={modal.close}>
              Powrót
            </Button>

            <Button
              className="primary-button w-100"
              onClick={() => {
                navigate(`wizyta/${token}`);
                modal.close();
              }}
            >
              Dalej
            </Button>
          </div>
        </>
      )}
      {confirm === "waiting" && (
        <>
          <Result
            icon={<LoadingOutlined style={{ color: "var(--primary-color)" }} />}
            title="Potwierdź wizytę"
            subTitle="Aby potwierdzić wizytę kliknij w link z wiadomości, lub wpisz kod potwierdzający."
          />

          {enterCode ? (
            <Form onFinish={sendForm}>
              <Form.Item
                label="Kod potwierdzający"
                name="code"
                rules={[
                  { required: true, message: "Podaj kod potwierdzający!" },
                  {
                    validator: (rule, value) => {
                      if (value < 100000 || value > 999999)
                        return Promise.reject(
                          "Kod potwierdzający powinien mieć 6 znaków!"
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
                validateTrigger="onBlur"
                validateFirst
              >
                <InputNumber controls={false} />
              </Form.Item>

              <div className="center-row pt-10">
                <Button
                  className="secondary-button w-100"
                  onClick={modal.close}
                >
                  Anuluj
                </Button>

                <Button className="primary-button w-100" htmlType="submit">
                  Potwierdź
                </Button>
              </div>
            </Form>
          ) : (
            <div className="center-row pt-10">
              <Button className="secondary-button w-100" onClick={modal.close}>
                Anuluj
              </Button>

              <Button
                className="primary-button w-100"
                onClick={() => {
                  setEnterCode(true);
                }}
              >
                Wpisz kod
              </Button>
            </div>
          )}
        </>
      )}
      {(confirm === "error" || confirm === "error-date") && (
        <>
          <Result
            icon={<CloseCircleOutlined style={{ color: "red" }} />}
            title={
              confirm === "error"
                ? "Coś poszło nie tak!"
                : "Niestety, wybrany termin jest już niedostępny!"
            }
            subTitle="Możesz wrócić do strony głównej i spróbować umówić się ponownie."
          />
          <div className="center-row pt-10">
            <Button className="secondary-button w-100" onClick={modal.close}>
              Powrót
            </Button>
          </div>
        </>
      )}
    </>
  );
}
