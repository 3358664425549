import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";

import NavBar from "../NavBar";
import AppointmentCard from "./AppointmentCard";
import SuccessModal from "./SuccessModal";

import { useRefresh } from "../../redux/refreshReducer";
import { APPOINTMENT_URL } from "../../url/URL";
import axios from "axios";

import { useModal } from "../../redux/modalReducer";

const { Content } = Layout;

export default function AppointmentView() {
  const [appointment, setAppointment] = useState(null);
  const { token } = useParams();
  const refresh = useRefresh();
  const modal = useModal();

  const getAppointment = () => {
    axios
      .get(APPOINTMENT_URL + token)
      .then((response) => {
        if (response.status === 200) {
          setAppointment(response.data);
          if (!response.data.confirmed)
            modal.open({
              content: <SuccessModal success="success" />,
            });
        } else
          modal.open({
            content: <SuccessModal success="error-date" />,
          });
      })
      .catch((error) => {
        modal.open({
          content: <SuccessModal success="error" />,
        });
      });
  };

  useEffect(() => {
    refresh.setFcn(getAppointment);
    refresh.setIntv(600000);
    return refresh.clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navbarOptions = [
    { text: "strona główna", link: "/" },
    { text: "twoja wizyta", active: true },
  ];

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        {appointment && (
          <AppointmentCard appointment={appointment} token={token} />
        )}
      </Content>
    </Layout>
  );
}
