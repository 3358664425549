import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

import { useModal } from "../../redux/modalReducer";

export default function SuccessModal({ success }) {
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <>
      {success === "success" && (
        <>
          <Result
            icon={<CheckCircleFilled style={{ color: "#52c41a" }} />}
            title="Potwierdzono wizytę!"
            subTitle="Możesz teraz przejść do swojej wizyty lub wrócić do strony głównej."
          />
          <div className="center-row pt-10">
            <Button
              className="secondary-button w-100"
              onClick={() => {
                modal.close();
                navigate("/");
              }}
            >
              Powrót
            </Button>

            <Button className="primary-button w-100" onClick={modal.close}>
              Dalej
            </Button>
          </div>
        </>
      )}
      {success === "error-date" && (
        <>
          <Result
            icon={<CloseCircleOutlined style={{ color: "red" }} />}
            title="Niestety, wybrany termin jest już niedostępny!"
            subTitle="Możesz wrócić do strony głównej i spróbować umówić się ponownie."
          />

          <div className="center-row pt-10">
            <Button
              className="secondary-button w-100"
              onClick={() => {
                modal.close();
                navigate("/");
              }}
            >
              Powrót
            </Button>
          </div>
        </>
      )}
      {success === "error" && (
        <>
          <Result
            icon={<CloseCircleOutlined style={{ color: "red" }} />}
            title="Coś poszło nie tak!"
            subTitle="Spróbuj ponownie za chwilę."
          />

          <div className="center-row pt-10">
            <Button
              className="secondary-button w-100"
              onClick={() => {
                modal.close();
                navigate("/");
              }}
            >
              Powrót
            </Button>
          </div>
        </>
      )}
    </>
  );
}
