import React from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";

import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";

import { CALENDAR_APPOINTMENT_URL, useRequest } from "../../url/URL";

export default function FormModal({ appointments, date, time }) {
  const refresh = useRefresh();
  const modal = useModal();
  const sendForm = useRequest({
    method: "POST",
    url: CALENDAR_APPOINTMENT_URL,
    before: (values) => {
      values.description =
        values.description === undefined ? "" : values.description;
      if (appointments.length === 1) values.type = appointments[0].id;
    },
    on200: refresh.run,
  });

  return (
    <Form onFinish={sendForm}>
      <Form.Item
        label="Imię"
        name="patient_name"
        rules={[{ required: true, message: "Podaj swoje imię!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Nazwisko"
        name="patient_surname"
        rules={[{ required: true, message: "Podaj swoje nazwisko!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Numer telefonu"
        name="patient_phone"
        rules={[
          { required: true, message: "Podaj swój numer telefonu!" },
          {
            validator: (rule, value) => {
              if (value < 100000000 || value > 999999999)
                return Promise.reject("Niepoprawny numer telefonu!");
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onBlur"
        validateFirst
      >
        <InputNumber
          className="addon-before"
          addonBefore="+48"
          controls={false}
        />
      </Form.Item>

      {appointments.length === 1 && (
        <Form.Item
          label="Rodzaj wizyty"
          name="type"
          initialValue={`${appointments[0].type}, ${appointments[0].price}zł`}
          required
        >
          <Input disabled />
        </Form.Item>
      )}
      {appointments.length > 1 && (
        <Form.Item
          label="Rodzaj wizyty"
          name="type"
          rules={[{ required: true, message: "Wybierz rodzaj wizyty!" }]}
        >
          <Select>
            {appointments.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {`${type.type}, ${type.price}zł`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label="Data"
        name="date"
        initialValue={`${date} ${time}`}
        required
      >
        <Input disabled />
      </Form.Item>

      <Form.Item label="Opis wizyty" name="description">
        <Input.TextArea maxLength={1000} rows={3} style={{ resize: "none" }} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button className="primary-button w-100" htmlType="submit">
          Dodaj
        </Button>
      </div>
    </Form>
  );
}
