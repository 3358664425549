import { Layout, Form, Input, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import NavBar from "../NavBar";
import { RESET_PASSWORD_URL, useRequest } from "../../url/URL";

const { Content } = Layout;

export default function ResetPasswordView() {
  const navigate = useNavigate();
  const navbarOptions = [{ text: "strona główna", link: "/" }];
  const { id, token } = useParams();

  const sendForm = useRequest({
    method: "PUT",
    url: RESET_PASSWORD_URL + "/" + id + "/" + token,
    after: () => {
      navigate("/");
    },
  });

  return (
    <Layout>
      <NavBar options={navbarOptions} />
      <Content className="content">
        <div className="container">
          <div className="card">
            <h2
              className="m-0 pb-5 mb-5 text-center"
              style={{ borderBottom: "1px solid var(--secondary-color)" }}
            >
              Resetuj hasło
            </h2>
            <Form onFinish={sendForm}>
              <Form.Item
                label="Hasło"
                name="password1"
                rules={[
                  { required: true, message: "Podaj hasło!" },
                  {
                    min: 8,
                    message: "Hasło powinno mieć przynajmniej 8 znaków.",
                  },
                ]}
                validateFirst
                help={
                  <ul style={{ listStyleType: "disc", paddingLeft: "10px" }}>
                    <li key="1">
                      Twoje hasło nie może być zbyt podobne do twoich innych
                      danych osobistych.
                    </li>
                    <li key="2">
                      {" "}
                      Twoje hasło musi zawierać co najmniej 8 znaków.
                    </li>{" "}
                    <li key="3">
                      Twoje hasło nie może być powszechnie używanym hasłem.
                    </li>{" "}
                    <li key="4">
                      {" "}
                      Twoje hasło nie może składać się tylko z cyfr.
                    </li>
                  </ul>
                }
              >
                <Input.Password maxLength={100} />
              </Form.Item>

              <Form.Item
                label="Powtórz hasło"
                name="password2"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Powtórz hasło!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password1") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Podane hasła nie są takie same!");
                    },
                  }),
                ]}
              >
                <Input.Password maxLength={100} />
              </Form.Item>

              <div className="center-row pt-10">
                <Button className="primary-button w-100" htmlType="submit">
                  Resetuj
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
