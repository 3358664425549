import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, InputNumber, Button } from "antd";

import { PATIENT_URL, useRequest } from "../../url/URL";

import { useModal } from "../../redux/modalReducer";

export default function PatientModal() {
  const navigate = useNavigate();
  const modal = useModal();
  const sendForm = useRequest({
    method: "POST",
    url: PATIENT_URL,
    on200: (response) => {
      setTimeout(
        () =>
          navigate(`wizyta/${response.data.token}`, {
            replace: true,
          }),
        250
      );
    },
    alertOn200: false,
  });

  return (
    <Form
      onFinish={(values) => {
        sendForm(values);
      }}
    >
      <Form.Item
        label="Imię"
        name="name"
        rules={[{ required: true, message: "Podaj swoje imię!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Nazwisko"
        name="surname"
        rules={[{ required: true, message: "Podaj swoje nazwisko!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Numer telefonu"
        name="phone"
        rules={[
          { required: true, message: "Podaj swój numer telefonu!" },
          {
            validator: (rule, value) => {
              if (value < 100000000 || value > 999999999)
                return Promise.reject("Niepoprawny numer telefonu!");
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onBlur"
        validateFirst
      >
        <InputNumber
          className="addon-before"
          addonBefore="+48"
          controls={false}
        />
      </Form.Item>

      <Form.Item
        label="Kod potwierdzający"
        name="code"
        rules={[
          { required: true, message: "Podaj kod potwierdzający!" },
          {
            validator: (rule, value) => {
              if (value < 100000 || value > 999999)
                return Promise.reject(
                  "Kod potwierdzający powinien mieć 6 znaków!"
                );
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onBlur"
        validateFirst
      >
        <InputNumber controls={false} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button className="primary-button w-100" htmlType="submit">
          Dalej
        </Button>
      </div>
    </Form>
  );
}
