import React from "react";
import { Button, Upload, Switch } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  MedicineBoxOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CameraOutlined,
  GoogleOutlined,
} from "@ant-design/icons";

import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import { useAlert } from "../../redux/alertReducer";
import _URL, { PROFILE_URL, useRequest } from "../../url/URL";

import WorkdaysList from "./WorkdaysList";
import TypesList from "./TypesList";
import ProfileModal from "./ProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";

export default function ProfileCard({
  profile,
  loadingSwitch,
  setLoadingSwitch,
}) {
  const refresh = useRefresh();
  const modal = useModal();
  const alert = useAlert();
  const {
    doctor,
    specialisations,
    types,
    workdays,
    weekdays,
    durations,
    cities,
  } = profile;

  const handleGoogleCalendar = useRequest({
    method: "GET",
    url: PROFILE_URL + "/googlecalendar",
    on200: (response) => {
      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      } else {
        refresh.run();
        setLoadingSwitch(false);
      }
    },
    onError: () => {
      refresh.run();
      setLoadingSwitch(false);
    },
    before: () => {
      doctor.googlecalendar = !doctor.googlecalendar;
      setLoadingSwitch(true);
    },
  });

  const handleEditClick = (event) => {
    modal.open({
      title: "Edytuj profil",
      content: (
        <ProfileModal
          doctor={doctor}
          specialisations={specialisations}
          durations={durations}
          cities={cities}
        />
      ),
    });
  };

  const handleChangePasswordClick = (event) => {
    modal.open({
      title: "Zmień hasło",
      content: <ChangePasswordModal />,
    });
  };

  const handleDuration = (rawDuration) => {
    try {
      const duration = rawDuration.split(":").map((dur) => parseInt(dur));
      if (duration[0]) {
        if (duration[0] === 1)
          return `1 godzina ${duration[1] && `${duration[1]} minut`}`;
        return `${duration[0]} godziny ${
          duration[1] && `${duration[1]} minut`
        }`;
      }
      return `${duration[1]} minut`;
    } catch {
      return rawDuration;
    }
  };

  const uploadProps = {
    name: "picture",
    action: PROFILE_URL,
    method: "PUT",
    headers: {
      authorization: `Token ${window.localStorage.getItem("token")}`,
    },
    showUploadList: false,
    accept: ".png, .jpg, .jpeg",
    onChange(info) {
      if (info.file.status === "done") {
        refresh.run();
        alert({
          type: info.file.response.alert,
          message: info.file.response.message,
        });
      } else if (info.file.status === "error") {
        alert({
          type: info.file.response.alert,
          message: info.file.response.message,
        });
      }
    },
  };

  return (
    <div className="container">
      <div className="card card-sm">
        <div className="center-col">
          <div style={{ position: "relative" }}>
            <img
              className="image"
              src={_URL + doctor.picture}
              alt={`${doctor.name} ${doctor.surname} ${doctor.specialisation.specialisation} ${doctor.city.city}`}
            />
            <Upload {...uploadProps}>
              <Button
                className="center"
                style={{
                  backgroundColor: "#dee1e3",
                  height: "18px",
                  width: "18px",
                  border: "2px solid var(--secondary-color)",
                  borderRadius: "18px",
                  position: "absolute",
                  top: "36px",
                  right: "0px",
                  cursor: "pointer",
                }}
                icon={
                  <CameraOutlined
                    style={{
                      fontSize: "12px",
                      color: "var(--secondary-color)",
                    }}
                  />
                }
              ></Button>
            </Upload>
          </div>
          <h2 className="m-0 pt-5 ml-10">
            {`${doctor.name} ${doctor.surname}`}
          </h2>
        </div>
        <h3 className="m-0 pt-5">{doctor.specialisation.specialisation}</h3>
        {doctor.description && <p className="m-0 pt-5">{doctor.description}</p>}
        <hr className="divider" />
        <div className="center-col pt-5">
          <MailOutlined className="card-icon" />
          {doctor.email}
        </div>
        <div className="center-col pt-5">
          <PhoneOutlined className="card-icon" />
          {doctor.phone}
        </div>
        <div className="center-col pt-10">
          <EnvironmentOutlined className="card-icon" />
          {`${doctor.city.city}, ${doctor.address}`}
        </div>
        <div className="center-col pt-10">
          <ClockCircleOutlined className="card-icon" />
          {handleDuration(doctor.duration.duration)}
        </div>
        <div className="flex pt-10">
          <CalendarOutlined className="card-icon" />
          <WorkdaysList workdays={workdays} weekdays={weekdays} />
        </div>
        <div className="flex pt-10">
          <MedicineBoxOutlined className="card-icon" />
          <TypesList types={types} />
        </div>
        <div className="center-col pt-10">
          <GoogleOutlined className="card-icon" />
          Synchronizacja z kalendarzem Google
          <Switch
            checked={doctor.googlecalendar}
            onClick={() => {
              if (!loadingSwitch) handleGoogleCalendar();
            }}
            loading={loadingSwitch}
          />
        </div>
        <div className="center-row pt-10">
          <Button className="primary-button w-100" onClick={handleEditClick}>
            Edytuj dane
          </Button>
          <Button
            className="secondary-button w-100"
            onClick={handleChangePasswordClick}
          >
            Zmień hasło
          </Button>
        </div>
      </div>
    </div>
  );
}
