import React, { useState } from "react";
import { Input, Form, Select } from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import ConfirmModalContent from "../modal/ConfirmModalContent";

import { WORKDAY_URL, useRequest } from "../../url/URL";
import { useModal } from "../../redux/modalReducer";
import { useRefresh } from "../../redux/refreshReducer";

const WorkdayItem = ({ workday, weekdays }) => {
  const [editWorkday, setEditWorkday] = useState(false);
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const modal = useModal();
  const sendForm = useRequest({
    method: "PUT",
    url: WORKDAY_URL + workday.id,
    on200: () => {
      setEditWorkday(false);
      refresh.run();
    },
  });
  const deleteWorkday = useRequest({
    method: "DELETE",
    url: WORKDAY_URL + workday.id,
    on200: refresh.run,
  });

  return (
    <li key={workday.id}>
      {editWorkday ? (
        <div className="center-col" style={{ justifyContent: "space-between" }}>
          <Form
            onFinish={sendForm}
            form={form}
            initialValues={{
              weekday: workday.weekday.id,
              start: workday.start,
              end: workday.end,
            }}
            style={{ width: "100%" }}
            preserve={false}
          >
            <Form.Item name="weekday">
              <Select>
                <Select.Option
                  key={workday.weekday.id}
                  value={workday.weekday.id}
                  disabled
                >
                  {workday.weekday.weekday}
                </Select.Option>
                {weekdays.map((weekday) => (
                  <Select.Option key={weekday.id} value={weekday.id}>
                    {weekday.weekday}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="flex-row center-col time-input-wrapper">
              <Form.Item name="start">
                <Input style={{ marginRight: "5px" }} type="time" />
              </Form.Item>
              <div className="mr-5">-</div>
              <Form.Item name="end">
                <Input className="ml-5" type="time" />
              </Form.Item>
            </div>
          </Form>
          <div className="flex">
            <CheckCircleOutlined
              style={{ color: "var(--primary-color)", margin: "2px" }}
              onClick={() => form.submit()}
            />
            <CloseCircleOutlined
              style={{ color: "var(--secondary-color)", margin: "2px" }}
              onClick={() => setEditWorkday(false)}
            />
          </div>
        </div>
      ) : (
        <div className="flex" style={{ justifyContent: "space-between" }}>
          {`${workday.weekday.weekday} ${workday.start}-${workday.end}`}
          <div className="flex">
            <EditOutlined
              style={{ color: "var(--secondary-color)", margin: "2px" }}
              onClick={() => setEditWorkday(true)}
            />
            <MinusCircleOutlined
              style={{ color: "var(--danger-color)", margin: "2px" }}
              onClick={() => {
                modal.open({
                  title: "Czy na pewno chcesz usunąć dzień?",
                  content: (
                    <ConfirmModalContent onOk={deleteWorkday} theme="danger" />
                  ),
                });
              }}
            />
          </div>
        </div>
      )}
    </li>
  );
};

export default function WorkdaysList({ workdays, weekdays }) {
  const [addWorkday, setAddWorkday] = useState(false);
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const sendForm = useRequest({
    method: "POST",
    url: WORKDAY_URL,
    on200: () => {
      setAddWorkday(false);
      refresh.run();
    },
  });

  return (
    <ul style={{ width: "100%" }}>
      {workdays.map((workday, index) => (
        <WorkdayItem key={workday.id} workday={workday} weekdays={weekdays} />
      ))}

      <li key="0">
        {addWorkday ? (
          <div
            className="center-col"
            style={{ justifyContent: "space-between" }}
          >
            <Form
              onFinish={sendForm}
              form={form}
              style={{ width: "100%" }}
              preserve={false}
            >
              <Form.Item name="weekday">
                <Select placeholder="wybierz dzień">
                  {weekdays.map((weekday) => (
                    <Select.Option key={weekday.id} value={weekday.id}>
                      {weekday.weekday}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="flex-row center-col time-input-wrapper">
                <Form.Item name="start">
                  <Input style={{ marginRight: "5px" }} type="time" />
                </Form.Item>
                <div className="mr-5">-</div>
                <Form.Item name="end">
                  <Input className="ml-5" type="time" />
                </Form.Item>
              </div>
            </Form>
            <div className="flex">
              <CheckCircleOutlined
                style={{ color: "var(--primary-color)", margin: "2px" }}
                onClick={() => form.submit()}
              />
              <CloseCircleOutlined
                style={{ color: "var(--secondary-color)", margin: "2px" }}
                onClick={() => setAddWorkday(false)}
              />
            </div>
          </div>
        ) : (
          <div className="flex" style={{ justifyContent: "end" }}>
            <PlusCircleOutlined
              style={{ color: "var(--primary-color)", margin: "2px" }}
              onClick={() => setAddWorkday(true)}
            />
          </div>
        )}
      </li>
    </ul>
  );
}
