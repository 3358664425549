import React, { useState } from "react";
import { Form, Input, Button } from "antd";

import { useModal } from "../../redux/modalReducer";

import { RESET_PASSWORD_URL, useRequest } from "../../url/URL";

export default function ResetPasswordModal() {
  const [sending, setSending] = useState(false);
  const modal = useModal();
  const sendForm = useRequest({
    method: "POST",
    url: RESET_PASSWORD_URL,
    before: () => setSending(true),
    after: () => setSending(false),
  });

  return (
    <Form onFinish={sendForm}>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Podaj adres email!" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button
          className="primary-button w-100"
          htmlType="submit"
          loading={sending}
        >
          Przypomnij
        </Button>
      </div>
    </Form>
  );
}
