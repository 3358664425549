import React from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";

import { useModal } from "../../redux/modalReducer";
import { useRefresh } from "../../redux/refreshReducer";

import { PROFILE_URL, useRequest } from "../../url/URL";

export default function ProfileModal({
  doctor,
  specialisations,
  durations,
  cities,
}) {
  const refresh = useRefresh();
  const modal = useModal();
  const sendForm = useRequest({
    method: "PUT",
    url: PROFILE_URL,
    on200: refresh.run,
  });

  const initialValues = {
    name: doctor.name,
    surname: doctor.surname,
    specialisation: doctor.specialisation.id,
    phone: doctor.phone,
    city: doctor.city.id,
    address: doctor.address,
    duration: doctor.duration.id,
    description: doctor.description,
  };

  return (
    <Form onFinish={sendForm} initialValues={initialValues}>
      <Form.Item
        label="Imię"
        name="name"
        rules={[{ required: true, message: "Podaj imię!" }]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Nazwisko"
        name="surname"
        rules={[{ required: true, message: "Podaj nazwisko!" }]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Specjalizacja"
        name="specialisation"
        rules={[{ required: true, message: "Wybierz specjalizację!" }]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          filterOption={(value, option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
          }
          notFoundContent={
            <div className="ant-select-no-content">Brak wyników</div>
          }
          options={specialisations.map((specialisation) => {
            return {
              value: specialisation.id,
              label: specialisation.specialisation,
            };
          })}
        ></Select>
      </Form.Item>

      <Form.Item
        label="Numer telefonu"
        name="phone"
        rules={[
          { required: true, message: "Podaj swój numer telefonu!" },
          {
            validator: (rule, value) => {
              if (value < 100000000 || value > 999999999)
                return Promise.reject("Niepoprawny numer telefonu!");
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onBlur"
        validateFirst
      >
        <InputNumber
          className="addon-before"
          addonBefore="+48"
          controls={false}
        />
      </Form.Item>

      <Form.Item
        label="Miasto"
        name="city"
        rules={[{ required: true, message: "Wybierz miasto" }]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          filterOption={(value, option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
          }
          notFoundContent={
            <div className="ant-select-no-content">Brak wyników</div>
          }
          options={cities.map((city) => {
            return { id: city.id, value: city.id, label: city.city };
          })}
        ></Select>
      </Form.Item>

      <Form.Item
        label="Adres"
        name="address"
        rules={[{ required: true, message: "Podaj adres" }]}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        label="Czas trwania wizyty"
        name="duration"
        rules={[{ required: true, message: "Podaj czas trwania wizyty!" }]}
      >
        <Select
          options={durations.map((duration) => {
            return { value: duration.id, label: duration.duration };
          })}
        ></Select>
      </Form.Item>

      <Form.Item label="Opis" name="description">
        <Input.TextArea maxLength={1000} rows={3} style={{ resize: "none" }} />
      </Form.Item>

      <div className="center-row pt-10">
        <Button className="secondary-button w-100" onClick={modal.close}>
          Anuluj
        </Button>

        <Button className="primary-button w-100" htmlType="submit">
          Zapisz
        </Button>
      </div>
    </Form>
  );
}
