import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Carousel } from "antd";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";

import DatePicker, { isSameDay } from "../datepicker/DatePicker";
import TimePicker from "../datepicker/TimePicker";

import _URL, { APPOINTMENT_URL, useRequest } from "../../url/URL";
import ConfirmModalContent from "../modal/ConfirmModalContent";
import { useWindowWidth } from "../../redux/windowReducer";
import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";

export default function AppointmentCard({ appointment, token }) {
  const availableDates = appointment.availableDates.map(
    (date) => new Date(date)
  );
  const [currentDate, setCurrentDate] = useState(
    availableDates.length ? availableDates[0] : new Date()
  );
  const windowWidth = useWindowWidth();
  const carouselRef = useRef(null);
  const navigate = useNavigate();
  const refresh = useRefresh();
  const modal = useModal();

  const postponeAppointment = useRequest({
    method: "PUT",
    url: APPOINTMENT_URL + token,
    on200: () => {
      refresh.run();
      if (windowWidth < 768) carouselRef.current.goTo(0);
    },
    closeModalOn203: true,
    closeModalOnError: true,
  });

  const cancelAppointment = useRequest({
    method: "DELETE",
    url: APPOINTMENT_URL + token,
    on200: () => {
      navigate("/");
    },
    closeModalOn203: true,
    closeModalOnError: true,
  });

  const handleTimePicker = (event) => {
    modal.open({
      title: "Czy na pewno chcesz przełożyć wizytę?",
      content: (
        <ConfirmModalContent
          text={`Nowy termin: ${currentDate.toLocaleDateString()} ${
            event.target.innerText
          }`}
          onOk={() =>
            postponeAppointment({
              date: `${currentDate.toLocaleDateString()} ${
                event.target.innerText
              }`,
            })
          }
        />
      ),
    });
  };

  const handleCancel = () => {
    modal.open({
      title: "Czy na pewno chcesz odwołać wizytę?",
      content: <ConfirmModalContent onOk={cancelAppointment} theme="danger" />,
    });
  };

  const handleDuration = (rawDuration) => {
    try {
      const duration = rawDuration.split(":").map((dur) => parseInt(dur));
      if (duration[0]) {
        if (duration[0] === 1)
          return `{1 godzina ${duration[1] && `${duration[1]} minut`}}`;
        return `{${duration[0]} godziny ${
          duration[1] && `${duration[1]} minut`
        }}`;
      }
      return `${duration[1]} minut`;
    } catch {
      return rawDuration;
    }
  };

  return (
    <div className="container">
      {windowWidth < 768 ? (
        <div className="card card-sm">
          <div className="center-col">
            <img
              className="image"
              src={_URL + appointment.picture}
              alt={`${appointment.name} ${appointment.surname} ${appointment.specialisation} ${appointment.city}`}
            />
            <h2 className="m-0 pt-5 ml-10">
              {`${appointment.name} ${appointment.surname}`}
            </h2>
          </div>
          <h3 className="m-0 pt-5">{appointment.specialisation}</h3>
          {appointment.doctor_description && (
            <p className="m-0 pt-5">{appointment.doctor_description}</p>
          )}
          <hr className="divider" />
          <Carousel
            infinite={false}
            slidesToShow={1}
            draggable={true}
            dots={false}
            style={{ overflow: "hidden" }}
            ref={carouselRef}
          >
            <div>
              <div className="center-col pt-5">
                <PhoneOutlined className="card-icon" />
                {appointment.phone}
              </div>
              <div className="center-col pt-10">
                <EnvironmentOutlined className="card-icon" />
                {`${appointment.city}, ${appointment.address}`}
              </div>
              <div className="center-col pt-10">
                <ScheduleOutlined className="card-icon" />
                {appointment.date}
              </div>
              <div className="center-col pt-10">
                <MedicineBoxOutlined className="card-icon" />
                {`${appointment.type}, ${appointment.price}zł`}
              </div>
              <div className="center-col pt-10">
                <ClockCircleOutlined className="card-icon" />
                {handleDuration(appointment.duration)}
              </div>
              {appointment.description && (
                <div className="center-col pt-10">
                  <FormOutlined className="card-icon" />
                  {appointment.description}
                </div>
              )}
              <div className="center-row pt-10">
                <Button className="danger-button w-120" onClick={handleCancel}>
                  Odwołaj wizytę
                </Button>
                <Button
                  className="secondary-button w-120"
                  onClick={() => carouselRef.current.next()}
                >
                  Przełóż wizytę
                </Button>
              </div>
            </div>
            <div>
              <DatePicker
                date={currentDate}
                onDateChanged={setCurrentDate}
                availableDates={availableDates}
              />
              <TimePicker
                availableTimes={availableDates.filter((date) =>
                  isSameDay(currentDate, date)
                )}
                handleClick={handleTimePicker}
                theme="secondary"
              />{" "}
            </div>
          </Carousel>
        </div>
      ) : (
        <div
          className="card card-lg flex-row"
          style={{ minHeight: appointment.description ? "275px" : "" }}
        >
          <div className="w-250">
            <div className="center-col">
              <img
                className="image"
                src={_URL + appointment.picture}
                alt={`${appointment.name} ${appointment.surname} ${appointment.specialisation} ${appointment.city}`}
              />
              <h2 className="m-0 pt-5 ml-10">
                {`${appointment.name} ${appointment.surname}`}
              </h2>
            </div>
            <div className="w-250">
              <h3 className="m-0 pt-5">{appointment.specialisation}</h3>
              {appointment.description && (
                <p
                  className="m-0 pt-5"
                  style={{ maxHeight: "45px", overflow: "hidden" }}
                >
                  {appointment.description}
                </p>
              )}
              <div className="center-col pt-5">
                <PhoneOutlined className="card-icon" />
                {appointment.phone}
              </div>
              <div className="center-col pt-10">
                <EnvironmentOutlined className="card-icon" />
                {`${appointment.city}, ${appointment.address}`}
              </div>
              <div className="center-col pt-10">
                <ScheduleOutlined className="card-icon" />
                {appointment.date}
              </div>
              <div className="center-col pt-10">
                <MedicineBoxOutlined className="card-icon" />
                {`${appointment.type}, ${appointment.price}zł`}
              </div>
              <div className="center-col pt-10">
                <ClockCircleOutlined className="card-icon" />
                {handleDuration(appointment.duration)}
              </div>
              {appointment.description && (
                <div className="center-col pt-10">
                  <FormOutlined className="card-icon" />
                  {appointment.description}
                </div>
              )}
              <div className="center-row pt-10">
                <Button className="danger-button w-120" onClick={handleCancel}>
                  Odwołaj wizytę
                </Button>
              </div>
            </div>
          </div>
          <div className="divider-vertical" />
          <div className="center text-center w-250">
            <div style={{ width: "100%" }}>
              <h4 className="m-0 p-10">Wybierz termin, aby przełożyć wizytę</h4>
              <DatePicker
                date={currentDate}
                onDateChanged={setCurrentDate}
                availableDates={availableDates}
              />
              <TimePicker
                availableTimes={availableDates.filter((date) =>
                  isSameDay(currentDate, date)
                )}
                handleClick={handleTimePicker}
                theme="secondary"
              />{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
