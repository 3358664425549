import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

export const refreshState = createSlice({
  name: "refreshState",
  initialState: { refresh: () => {}, intervalHandler: null },
  reducers: {
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    setRefreshInterval: (state, action) => {
      state.intervalHandler = action.payload;
    },
    clearRefresh: (state) => {
      state.refresh = () => {};
      state.intervalHandler = null;
    },
  },
});

export default refreshState.reducer;
export const { setRefresh, setRefreshInterval, clearRefresh } =
  refreshState.actions;

export const useRefresh = () => {
  const refresh = useSelector((state) => state.refreshState);
  const dispatch = useDispatch();
  return {
    run: refresh.refresh,
    setFcn: (fcn) => {
      fcn();
      dispatch(setRefresh(fcn));
      clearInterval(refresh.intervalHandler);
    },
    setIntv: (intv) => {
      clearInterval(refresh.intervalHandler);
      const intervalHandler = setInterval(refresh.refresh, intv);
      dispatch(setRefreshInterval(intervalHandler));
    },
    clear: () => {
      clearInterval(refresh.intervalHandler);
      dispatch(clearRefresh());
    },
  };
};
