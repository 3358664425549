import React, { useState } from "react";
import { Input, InputNumber, Form } from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import ConfirmModalContent from "../modal/ConfirmModalContent";

import { APPOINTMENTTYPE_URL, useRequest } from "../../url/URL";
import { useModal } from "../../redux/modalReducer";
import { useRefresh } from "../../redux/refreshReducer";

const TypeItem = ({ type }) => {
  const [editType, setEditType] = useState(false);
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const modal = useModal();
  const sendForm = useRequest({
    method: "PUT",
    url: APPOINTMENTTYPE_URL + type.id,
    on200: () => {
      setEditType(false);
      refresh.run();
    },
  });
  const deleteType = useRequest({
    method: "DELETE",
    url: APPOINTMENTTYPE_URL + type.id,
    on200: refresh.run,
  });

  return (
    <li key={type.id}>
      {editType ? (
        <div className="center-col" style={{ justifyContent: "space-between" }}>
          <Form
            form={form}
            onFinish={sendForm}
            initialValues={{ type: type.type, price: type.price }}
            preserve={false}
          >
            <Form.Item
              name="type"
              rules={[{ required: true, message: "Podaj rodzaj wizyty!" }]}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              name="price"
              label={<label>Cena:</label>}
              className="center-col"
              labelCol={{ className: "mb-5 mr-5" }}
              rules={[{ required: true, message: "Podaj cenę wizyty!" }]}
              required={false}
            >
              <InputNumber
                className="addon-after"
                addonAfter="zł"
                controls={false}
                min={0}
              />
            </Form.Item>
          </Form>
          <div className="flex">
            <CheckCircleOutlined
              style={{ color: "var(--primary-color)", margin: "2px" }}
              onClick={() => form.submit()}
            />
            <CloseCircleOutlined
              style={{ color: "var(--secondary-color)", margin: "2px" }}
              onClick={() => setEditType(false)}
            />
          </div>
        </div>
      ) : (
        <div className="flex" style={{ justifyContent: "space-between" }}>
          {`${type.type}, ${type.price}zł`}
          <div className="flex">
            <EditOutlined
              style={{ color: "var(--secondary-color)", margin: "2px" }}
              onClick={() => setEditType(true)}
            />
            <MinusCircleOutlined
              style={{ color: "var(--danger-color)", margin: "2px" }}
              onClick={() => {
                modal.open({
                  title: "Czy na pewno chcesz usunąć rodzaj wizyty?",
                  content: (
                    <ConfirmModalContent
                      text="Wszystkie wizyty zostaną odwołane."
                      onOk={deleteType}
                      theme="danger"
                    />
                  ),
                });
              }}
            />
          </div>
        </div>
      )}
    </li>
  );
};

export default function TypesList({ types }) {
  const [addType, setAddType] = useState(false);
  const [form] = Form.useForm();
  const refresh = useRefresh();
  const sendForm = useRequest({
    method: "POST",
    url: APPOINTMENTTYPE_URL,
    on200: () => {
      setAddType(false);
      refresh.run();
    },
  });

  return (
    <>
      <ul style={{ width: "100%" }}>
        {types.map((type, index) => (
          <TypeItem key={type.id} type={type} />
        ))}
        <li key="0">
          {addType ? (
            <div
              className="center-col"
              style={{ justifyContent: "space-between" }}
            >
              <Form form={form} onFinish={sendForm} preserve={false}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "Podaj rodzaj wizyty!" }]}
                >
                  <Input maxLength={100} />
                </Form.Item>
                <Form.Item
                  name="price"
                  label={<label>Cena:</label>}
                  className="center-col"
                  labelCol={{ className: "mb-5 mr-5" }}
                  rules={[{ required: true, message: "Podaj cenę wizyty!" }]}
                  required={false}
                >
                  <InputNumber
                    className="addon-after"
                    addonAfter="zł"
                    controls={false}
                    min={0}
                  />
                </Form.Item>
              </Form>
              <div className="flex">
                <CheckCircleOutlined
                  style={{ color: "var(--primary-color)", margin: "2px" }}
                  onClick={() => form.submit()}
                />
                <CloseCircleOutlined
                  style={{ color: "var(--secondary-color)", margin: "2px" }}
                  onClick={() => setAddType(false)}
                />
              </div>
            </div>
          ) : (
            <div className="flex" style={{ justifyContent: "end" }}>
              <PlusCircleOutlined
                style={{ color: "var(--primary-color)", margin: "2px" }}
                onClick={() => setAddType(true)}
              />
            </div>
          )}
        </li>
      </ul>
    </>
  );
}
