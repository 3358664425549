import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

export const windowState = createSlice({
  name: "windowState",
  initialState: { width: window.width },
  reducers: {
    setWindow: (state, action) => {
      state.width = action.payload;
    },
  },
});

export default windowState.reducer;
export const { setWindow } = windowState.actions;

export const useWindowWidth = () => {
  const windowWidth = useSelector((state) => state.windowState.width);
  return windowWidth;
};

export const useSetWindowWidth = () => {
  const dispatch = useDispatch();
  return (width) => dispatch(setWindow(width));
};
