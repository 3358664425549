import { useState } from "react";
import { Menu, Button } from "antd";

import DatePicker, { isSameDay, addDays } from "../datepicker/DatePicker";
import TimePicker from "../datepicker/TimePicker";
import { useRefresh } from "../../redux/refreshReducer";
import { useModal } from "../../redux/modalReducer";
import { HOLIDAY_URL, useRequest } from "../../url/URL";
import FormModal from "./FormModal";

export default function EditMenu({
  menuState,
  availableDates,
  appointments,
  holidays,
  workdays,
  hideMenu,
}) {
  const refresh = useRefresh();
  const modal = useModal();
  const addHoliday = useRequest({
    method: "POST",
    url: HOLIDAY_URL,
    on200: refresh.run,
  });
  const deleteHoliday = useRequest({
    method: "DELETE",
    url: HOLIDAY_URL,
    on200: (response, data) => {
      let date = data.date.split(".");
      date = new Date(date[2], date[1], date[0]);
      date =
        date.getFullYear().toString() +
        "-" +
        appendLeadingZeroes(date.getMonth()).toString() +
        "-" +
        appendLeadingZeroes(date.getDate()).toString();

      const dayCell = document.querySelector(`[data-date^="${date}"]`);
      dayCell.firstChild.style.backgroundColor = "White";

      refresh.run();
    },
  });

  const appendLeadingZeroes = (n) => (n <= 9 ? "0" + n : n);

  const DatetimeModal = () => {
    const [currentDate, setCurrentDate] = useState(
      availableDates.length ? availableDates[0] : new Date()
    );
    return (
      <>
        <DatePicker
          date={currentDate}
          onDateChanged={setCurrentDate}
          availableDates={availableDates}
        />
        <TimePicker
          availableTimes={availableDates.filter((date) =>
            isSameDay(currentDate, date)
          )}
          handleClick={(event) => {
            modal.switch({
              title: "Ustal szczegóły wizyty",
              content: (
                <FormModal
                  appointments={appointments}
                  date={currentDate.toLocaleDateString()}
                  time={event.target.innerText}
                />
              ),
            });
          }}
        />{" "}
      </>
    );
  };

  const AddHolidayModal = () => {
    let availableDates = [];
    for (let i = 1; i < 60; i++) {
      const date = addDays(new Date(), i);
      if (
        holidays.every((holiday) => {
          let holiday_date = holiday.date.split(".");
          return !isSameDay(
            date,
            new Date(holiday_date[2], holiday_date[1] - 1, holiday_date[0])
          );
        }) &&
        workdays.includes(date.getDay() ? date.getDay() : 7)
      )
        availableDates.push(date);
    }
    const [currentDate, setCurrentDate] = useState(
      availableDates ? availableDates[0] : new Date()
    );
    return (
      <>
        <DatePicker
          date={currentDate}
          onDateChanged={setCurrentDate}
          availableDates={availableDates}
        />
        <p className="mt-10 mb-0">{`Dodać dzień wolny ${currentDate.toLocaleDateString()}?`}</p>
        <div className="center-row pt-10">
          <Button className="secondary-button w-50" onClick={modal.close}>
            Nie
          </Button>
          <Button
            className="primary-button w-50"
            onClick={() =>
              addHoliday({ date: currentDate.toLocaleDateString() })
            }
          >
            Tak
          </Button>
        </div>
      </>
    );
  };

  const DeleteHolidayModal = () => {
    let availableDates = holidays
      .map((holiday) => {
        let date = holiday.date.split(".");
        return new Date(date[2], date[1] - 1, date[0]);
      })
      .sort((a, b) => new Date(a) - new Date(b));
    const [currentDate, setCurrentDate] = useState(
      availableDates ? availableDates[0] : new Date()
    );
    return (
      <>
        <DatePicker
          date={currentDate}
          onDateChanged={setCurrentDate}
          availableDates={availableDates}
        />
        <p className="mt-10 mb-0">{`Usunąć dzień wolny ${currentDate.toLocaleDateString()}?`}</p>
        <div className="center-row pt-10">
          <Button className="secondary-button w-50" onClick={modal.close}>
            Nie
          </Button>
          <Button
            className="danger-button w-50"
            onClick={() =>
              deleteHoliday(
                { date: currentDate.toLocaleDateString() },
                HOLIDAY_URL + "/" + currentDate.toLocaleDateString()
              )
            }
          >
            Tak
          </Button>
        </div>
      </>
    );
  };

  return (
    <Menu
      style={{
        position: "absolute",
        left: `${menuState.positionX}px`,
        top: `${menuState.positionY}px`,
      }}
    >
      <Menu.Item
        key="0"
        onClick={() => {
          modal.open({
            title: "Wybierz datę wizyty",
            content: <DatetimeModal />,
          });
          hideMenu();
        }}
      >
        Dodaj wizytę
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          modal.open({
            title: "Dodaj dzień wolny",
            content: <AddHolidayModal />,
          });
          hideMenu();
        }}
      >
        Dodaj dzień wolny
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          modal.open({
            title: "Usuń dzień wolny",
            content: <DeleteHolidayModal />,
          });
          hideMenu();
        }}
        disabled={!holidays.length}
      >
        Usuń dzień wolny
      </Menu.Item>
    </Menu>
  );
}
