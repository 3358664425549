import { configureStore } from "@reduxjs/toolkit";
import modalState from "./modalReducer";
import alertState from "./alertReducer";
import refreshState from "./refreshReducer";
import windowState from "./windowReducer";

export default configureStore({
  reducer: {
    modalState,
    alertState,
    refreshState,
    windowState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "modalState/openModal",
          "refreshState/setRefresh",
          "refreshState/setRefreshInterval",
          "refreshState/clearRefresh",
        ],
        ignoredPaths: ["modalState.content", "refreshState"],
      },
    }),
});
